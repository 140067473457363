import styled from "styled-components";
import COLORS from "../../Common/Colors";
import { Button } from "antd";

export const ButtonOutlined = styled(Button)`
  padding: 5px 20px;
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 5px;
  background: transparent;
  font-weight: normal;
  color: ${COLORS.PRIMARY};
  white-space: nowrap;
  margin: 10px;

  &:hover {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
    outline: none;
  }
  &:focus {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
    outline: none;
  }
  @media (max-width: 900px) {
    margin: 10px 0px;
  }
 
`;

export const ButtonLink = styled(Button)`
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: ${COLORS.PRIMARY};
  white-space: nowrap;
  outline: none;

  &:hover {
    outline: none;
    color: ${COLORS.PRIMARY};
  }
  &:focus {
    outline: none;
  }
`;

export const ButtonFilled = styled(Button)`
  margin: 5px;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  max-height: 50px;
  background: ${COLORS.PRIMARY};
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
