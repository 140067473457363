import { Layout, Menu, Skeleton } from "antd";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import _map from "lodash/map";
import { times } from "lodash";
import Icon from "../../reusableComponent/SideNavIcon";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setInitialLoader } from "../../Redux/systemSlice";
import COLORS from "../../Common/Colors";

const { Item } = Menu;

const StyledMenuItem = styled(Item)`
  vertical-align: middle;
  border-radius: 10px;
  width: 100%;
  border: none !important;

  .ant-menu {
    border: 0 !important;
  }

  .ant-layout.ant-layout-has-sider {
    flex-direction: row;
    // min-height: 100% !important;
  }

  &.ant-menu-item a,
  .ant-menu-item a:hover,
  .ant-menu-item a:active {
    color: ${COLORS.sidebar_text} !important;
  }

  &.ant-menu-item-selected a,
  .ant-menu-item-selected a:hover,
  .ant-menu-item-selected a:active {
    color: ${COLORS.sidebar_active} !important;
  }
  &.ant-menu-item-selected {
    color: ${COLORS.sidebar_active} !important;
    background-color: ${(props) =>
      props.selected ? `${COLORS.secondary_sidebar}` : "inherit"} !important;
  }
`;

const StyledMenuSkeletonContainer = styled.div`
  text-align: center;
  padding: 10px 20px;
  .ant-skeleton {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Container = styled.div`
  ::-webkit-scrollbar-thumb {
    background-color: ${COLORS.secondary_sidebar};
    border-radius: 6px;
    font-size: 16px;
  }

  background: ${COLORS.sidebar_light} 0% 0% no-repeat padding-box;
  padding-right: 2px; // To give some space for the layout and also show the box shadow

  .sidebar-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${COLORS.sidebar_light} 0% 0% no-repeat padding-box;
    padding-top: 4px;
    box-shadow: 0px 4px 16px 0px #00000029;

    overflow-y: auto;
    overflow-x: hidden !important;
  }
  .ant-layout-sider-children {
    background: ${COLORS.sidebar_light} 0% 0% no-repeat padding-box;
    margin: 0;
  }

  .ant-layout.ant-layout-has-sider {
    flex-direction: row;
    // min-height: 100% !important;
  }

  .ant-menu {
    font-size: 0.825rem;
    background: ${COLORS.sidebar_light} 0% 0% no-repeat padding-box;
    /* margin: 0px 10px 0px 10px; */
  }
  .ant-menu-item {
    &:hover,
    &:focus {
      background: ${COLORS.sidebar_light} 0% 0% no-repeat padding-box;
      border-radius: 5px;
      box-shadow: 0px 3px 6px #00000029;
      color: ${COLORS.sidebar_text} !important;
    }
  }

  .ant-menu-item a{
    text-decoration: none !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu-selected,
  .ant-menu-item-selected {
    color: ${COLORS.sidebar_active} !important;
  }

  .ant-menu-inline {
    border: none !important;
  }
`;

const { Sider } = Layout;

const useMenuList = ({ activeMenuList, activePage }) => {
  const menuItems = _map(activeMenuList, (menu) => {
    const isSelected = activePage === menu.path;
    return (
      <StyledMenuItem
        key={menu?.key}
        selected={isSelected}
        className={`${isSelected ? "ant-menu-item-selected" : ""}`}
      >
        <Icon name={menu.icon} />
        <Link to={`/${menu?.path}`}>{menu?.title}</Link>
      </StyledMenuItem>
    );
  });

  return menuItems;
};

const SideBar = () => {
  const isSideBarCollapsed = useSelector((state) => state.systemTracking.isSideBarCollapsed);
  const initialLoader = useSelector((state) => state.systemTracking.initialLoader)
  const dispatch = useDispatch()

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setInitialLoader(false));
    }, 3000); 
    return () => clearTimeout(timer);
  }, []);

  const activeMenuList = useSelector((state) => state.systemTracking.activeMenuList)

  const menuSkeleton = (key) => (
    <Skeleton.Input block={true} active={true} size="small" key={key} />
  );

  const activePage = useLocation().pathname.replace("/", "");

  const menuItems = useMenuList({ activeMenuList, activePage });

  return (
    <Container>
      <div className="sidebar-container">
        <Sider
          collapsible
          collapsedWidth={0}
          collapsed={isSideBarCollapsed}
          trigger={null}
        >
          {initialLoader && (
            <StyledMenuSkeletonContainer>
              {times(10, (ind) => menuSkeleton(ind))}
            </StyledMenuSkeletonContainer>
          )}

          {!initialLoader && (
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={[activePage]}
              defaultOpenKeys={[""]}
            >
              {menuItems}
            </Menu>
          )}
        </Sider>
      </div>
    </Container>
  );
};

export default SideBar;
