

const report = {
    active: {
       MarginTop : '20px',
       display:'flex',
       gap: '20px'
    }, 
nactivebtn: {
padding: "25px",
 border: 'none', 
 color: 'blue'
    }, 
    no: {
     
    }, 
    totalno: {
      
        display: 'flex',
        flexDirection: 'row', // Align items horizontally
        justifyContent: 'space-between', // Add spacing between elements
        alignItems: 'center',
        flexWrap: 'wrap', // Allow items to wrap for smaller screens
        padding: '10px', // Add padding for spacing
    }, 
    addclient: {
 display: 'flex',
 justifyContent: 'flex-end', 
 width: '100%', // Ensure it takes full width
    marginTop: '10px', 



}, meadd: {
  paddingLeft: '20px', // Adjust for responsiveness
  paddingRight: '20px',
  backgroundColor: 'rgb(45,83,218)',
  outline: 'none',
  fontSize: '16px', // Adjust font size for better legibility
  borderRadius: '4px',
  width: '150px'
  },
 filterstyle : {
  position: 'relative',
  width: '180px',
  marginTop: '10px',
  marginLeft: '-30px',
  
 },
  inputstyle: {
      color: '#636980',
      background: 'none',
      border: '1px solid #E2E2E8',
      width: '100%',
      fontSize: '14px',
      fontWeight: '400',
      borderRadius: '24px',
      paddingLeft: '36px', // Add padding to prevent overlap with the image
      height: '32px', // Adjust as needed
      outline: 'none',
  },
  imagestyle: {
    
      position: 'absolute',
      left: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '16px', // Adjust as needed
      width: '16px', // Adjust as needed
    
  },
  actionContainer: {
    opacity: 0,
    pointerEvents: 'none', 
    display: 'flex',
    justifyContent: 'space-around',
    gap: '10px',
    transition: 'opacity 0.3s ease', 
  },
  actionContainerHover: {
    opacity: 1,
    pointerEvents: 'auto', 
  }, table: {
    MarginBottom: '65px'
  }, 
  inputbutton: {
    display: 'flex', 
    justifyContent: 'center', 
    marginRight: '30px',
    gap: '20px',
  },



  '@media (max-width: 768px)': {
    totalno: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    filterstyle: {
      maxWidth: '90%',
      marginLeft: '0',
    },
    inputstyle: {
      fontSize: '12px',
      paddingLeft: '30px',
    },
    meadd: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  },
  '@media (max-width: 480px)': {
    filterstyle: {
      width: '100%',
      maxWidth: '100%',
    },
    meadd: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    inputstyle: {
      fontSize: '12px',
      height: '28px',
    },
  },

}


export default report