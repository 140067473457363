import React, { useState, useEffect } from "react";
import styled from "styled-components";
import GridBuilder from "../../UIBuilderEngine/GridBuilder";
import { ButtonFilled } from "../../reusableComponent/UIButtons";
import { Form, message, Spin, Switch, Table, Checkbox } from "antd";
import UITable from "../../reusableComponent/UITable/UITable";
import { RightOutlined } from "@ant-design/icons";
import StyledClientWrapper from "./style";
import StepsProgress from "../../reusableComponent/StepProgress/StepsProgress";
import COLORS from "../../Common/Colors";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setGetAllClientData } from "../../Redux/systemSlice";
import { useDispatch, useSelector } from "react-redux";
import emailjs from "@emailjs/browser";

const AddNewClient = ({ selectedRow, setSelectedRow }) => {
  const [form] = Form.useForm();
  const getAllClientData = useSelector(
    (state) => state.systemTracking.getAllClientData
  );

  const dataSource = [
    {
      key: 1,
      clientName: "Dashboard - Mine",
      name: "dashboard",
      access: selectedRow?.dashboard,
      planType: "Basic",
    },
    {
      key: 2,
      clientName: "Check in and check out through Biometrics",
      name: "checkinout_biometric",
      access: selectedRow?.checkinout_biometric,
      planType: "Basic",
    },
    {
      key: 3,
      clientName: "Leave application through portal",
      name: "leaveapplication_portal",
      access: selectedRow?.leaveapplication_portal,
      planType: "Basic",
    },
    {
      key: 4,
      clientName: "Calendar view - Mine",
      name: "calender_view",
      access: selectedRow?.calender_view,
      planType: "Basic",
    },
    {
      key: 5,
      clientName: "Calendar view - Team",
      name: "calender_view",
      access: selectedRow?.calender_view,
      planType: "Basic",
    },
    {
      key: 6,
      clientName: "Calendar view - All",
      name: "calender_view",
      access: selectedRow?.calender_view,
      planType: "Basic",
    },
    {
      key: 7,
      clientName: "Standard Reporting (Daily Attendance & Leave Report)",
      name: "standard_reporting",
      access: selectedRow?.calender_view,
      planType: "Basic",
    },
    {
      key: 8,
      clientName: "Exception Reports - Late check-in ",
      name: "exception_report",
      access: selectedRow?.exception_report,
      planType: "Basic",
    },
    {
      key: 9,
      clientName: "Exception Reports - Early Check out",
      name: "exception_report",
      access: selectedRow?.exception_report,
      planType: "Basic",
    },
    {
      key: 10,
      clientName: "Exception Reports - Absent Report",
      name: "exception_report",
      access: selectedRow?.exception_report,
      planType: "Basic",
    },
    {
      key: 11,
      clientName: "Support",
      name: "support",
      access: selectedRow?.support,
      planType: "Basic",
    },
    {
      key: 12,
      clientName: "HR Resources - Policies",
      name: "hr_resources",
      access: selectedRow?.hr_resources,
      planType: "Lite",
    },
    {
      key: 13,
      clientName: "HR Resources - People",
      name: "hr_resources",
      access: selectedRow?.hr_resources,
      planType: "Lite",
    },
    {
      key: 14,
      clientName: "Manage",
      name: "manage",
      access: selectedRow?.manage,
      planType: "Lite",
    },
    {
      key: 15,
      clientName: "Shift Management",
      name: "shift_management",
      access: selectedRow?.shift_management,
      planType: "Lite",
    },

    {
      key: 16,
      clientName: "Document Management",
      name: "document_management",

      access: selectedRow?.document_management,
      planType: "Lite",
    },
    {
      key: 17,
      clientName: "Dashboard - My Team",
      name: "my_teams",

      access: selectedRow?.my_teams,
      planType: "Lite",
    },
    {
      key: 18,
      clientName: "Monthly Attendance Reports",
      name: "monthly_attandance_report",

      access: selectedRow?.monthly_attandance_report,
      planType: "Lite",
    },
    {
      key: 19,
      clientName: "Asset Management",
      name: "asset_management",

      access: selectedRow?.asset_management,
      planType: "Lite",
    },
    {
      key: 20,
      clientName: "Mobile App",
      name: "mobile_app",

      access: selectedRow?.mobile_app,
      planType: "Lite",
    },
    {
      key: 21,
      clientName: "Recruitment Management",
      name: "recruitment_manaement",

      access: selectedRow?.recruitment_manaement,
      planType: "Lite",
    },
    {
      key: 22,
      clientName: "Automated Onboarding",
      name: "automated_onboarding",

      access: selectedRow?.automated_onboarding,
      planType: "Lite",
    },
    {
      key: 23,
      clientName: "Appraisal Management",
      name: "apprisal_management",

      access: selectedRow?.apprisal_management,
      planType: "Lite",
    },
    {
      key: 24,
      clientName: "Dashboard - HR",
      name: "dashboard_hr",
      access: selectedRow?.dashboard_hr,
      planType: "Pro",
    },
    {
      key: 25,
      clientName: "Performance Management",
      name: "performance_management",

      access: selectedRow?.performance_management,
      planType: "Pro",
    },
    {
      key: 26,
      clientName: "Travel/ Expense Approvals Management",
      name: "travel_expenses",

      access: selectedRow?.travel_expenses,
      planType: "Pro",
    },

    {
      key: 27,
      clientName: "Expense Reimbursement",
      name: "expenses_reimbursment",

      access: selectedRow?.expenses_reimbursment,
      planType: "Pro",
    },
    {
      key: 28,
      clientName: "Disciplinary Management",
      name: "disciplinary_management",

      access: selectedRow?.disciplinary_management,
      planType: "Pro",
    },
    {
      key: 29,
      clientName: "Incident Reporting",
      name: "incident_reporting",
      access: selectedRow?.incident_reporting,
      planType: "Pro",
    },
    {
      key: 30,
      clientName: "Simba AI",
      name: "simba_ai",
      access: selectedRow?.simba_ai,
      planType: "Pro",
    },
    {
      key: 31,
      clientName: "Org Chart",
      name: "org_chart",
      access: selectedRow?.org_chart,
      planType: "Pro",
    },
    {
      key: 32,
      clientName: "Activity Log",
      name: "activity_log",
      access: selectedRow?.activity_log,
      planType: "Pro",
    },
    {
      key: 33,
      clientName: "Report Preferences",
      name: "report_preference",
      access: selectedRow?.report_preference,
      planType: "Pro",
    },
    {
      key: 34,
      clientName: "Integration with existing Payroll system",
      name: "integrateex_payroll",
      access: selectedRow?.integrateex_payroll,
      planType: "Pro",
    },
    {
      key: 35,
      clientName: "Payroll",
      name: "payroll",
      access: selectedRow?.payroll,
      planType: "Pro",
    },
  ];

  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ispermission, setIsPermission] = useState(false);
  const [clientPermissionData, setClientPermissionData] = useState(dataSource);
  console.log("loading--->", loading);
  const [menuList, setMenuList] = useState([]);
  const tabledata = [
    {
      id: 1,
      featurename: "Dashboard(Mine, My Team, HR)",
      // pricing: "-",
      subscription: "Basic | Lite | Pro",
    },
    {
      id: 2,
      featurename: "Check in and Check out through Biometrics",
      // pricing: "-",
      subscription: "Lite | Pro",
    },
    {
      id: 3,
      featurename: "Leave application through portal",
      // pricing: "-",
      subscription: "Lite",
    },
  ];

  const columns = [
    {
      title: "Feature name",
      dataIndex: "featurename",
      key: "featurename",
      width: "25%",
    },
    // {
    //   title: "Pricing",
    //   dataIndex: "pricing",
    //   key: "pricing",
    //   width: "25%",
    // },
    // {
    //   title: "Available in Subscription Model",
    //   dataIndex: "subscription",
    //   key: "subscription",
    //   width: "25%",
    // },
    {
      title: "Access",
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: () => {
        return <Switch size="small" />;
      },
    },
  ];

  const handleSubmitForm = async (e) => {
    try {
      await form.validateFields();
      setStep(1);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleClickCancel = () => {
    navigate("/client");
  };

  const SubcriptionPlanData = [
    { value: "basic", label: "Basic" },
    { value: "lite", label: "Lite" },
    { value: "pro", label: "Pro" },
  ];

  const [digitCount, setDigitCount] = useState(4);
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countryOptions = response.data.map((country) => ({
          label: country.name.common, // Display name
          value: country.name.common, // Country code as value
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const addNewClientForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [],
        fields: [
          [
            {
              id: 1,
              label: "Company Name:",
              type: "text",
              name: "company_name",
              Placeholder: "",
              maxLength: 50,
              validationRules: [
                { required: true, message: "Company name is required" },
                {
                  min: 3,
                  max: 50,
                  message: "Company name must be 3-50 alphabetic characters",
                },
                {
                  pattern: /^[a-zA-Z\s]*$/,
                  // message: "Company name cannot contain numbers or special characters",
                },
              ],
            },
            {
              id: 2,
              label: "Client Name:",
              type: "text",
              name: "client_name",
              Placeholder: "",
              maxLength: 50,
              validationRules: [
                { required: true, message: "Client name is required" },
                {
                  min: 3,
                  max: 50,
                  message: "Client name must be 3-50 alphabetic characters",
                },
                {
                  pattern: /^[a-zA-Z\s]*$/,
                  // message: "Client name cannot contain numbers or special characters",
                },
              ],
              onInput: (e) => {
                e.target.value = e.target.value.replace(/[0-9]/g, "");
              },
            },
          ],
          [
            {
              id: 3,
              label: "Location:",
              type: "select",
              name: "location",
              options: countries,
              Placeholder: "",
              validationRules: [
                { required: true, message: "Location is required" },
              ],
            },
            {
              id: 4,
              label: "Employee Count / Company Size:",
              type: "number",
              name: "employee_count",
              maxLength: 4,
              Placeholder: "",
              validationRules: [
                { required: true, message: "Employee Count is required" },
                {
                  pattern: /^[0-9]{1,4}$/,
                  message:
                    "Employee Count must be a number less than or equal to 4 characters",
                },
              ],
              CountNumber: digitCount,
            },
          ],
          [
            {
              id: 5,
              label: "Contact Person:",
              type: "text",
              name: "contact_person",
              Placeholder: "",
              maxLength: 30,
              validationRules: [
                {
                  required: true,
                  message: "Contact Person is required",
                },
                {
                  min: 3,
                  max: 30,
                  message: "Contact Person must be 3-50 alphabetic characters",
                },
                {
                  pattern: /^[a-zA-Z\s]*$/,
                  // message: "Contact Person cannot contain numbers or special characters",
                },
              ],
            },
            {
              id: 6,
              label: "Contact Number:",
              type: "phone",
              name: "contact_number",
              maxLength: 20,
              Placeholder: "",
              validationRules: [
                { required: true, message: "Contact Number is required" },
                {
                  min: 1,
                  max: 20,
                },
                {
                  pattern: /^\+?[0-9]{10,20}$/,
                  message: "Contact number must be between 10 to 20 digits",
                },
              ],
            },
          ],
          [
            {
              id: 7,
              label: "Email ID:",
              type: "email",
              name: "email_id",
              Placeholder: "",
              validationRules: [
                { required: true, message: "Email is required" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ],
            },
            {
              id: 8,
              label: "Amount ($):",
              type: "number",
              name: "amount",
              Placeholder: "",
              validationRules: [
                { required: true, message: "Amount is required" },
                {
                  pattern: /^[0-9]{1,10}$/,
                  message:
                    "Amount must be a number less than or equal to 10 characters",
                },
              ],
            },
            // {
            //   id: 8,
            //   label: "Password:",
            //   type: "password",
            //   name: "password",
            //   Placeholder: "",
            // }
          ],
          [
            {
              id: 9,
              label: "Subscription Plan:",
              type: "select",
              name: "subscription_plan",
              options: SubcriptionPlanData,
              Placeholder: "",
              validationRules: [
                { required: true, message: "Subscription Plan is required" },
              ],
            },
            {
              id: 10,
              label: "Subscription Frequency:",
              type: "select",
              name: "supscription_frequency",
              options: [
                { label: "Bi-annually", value: "biannually" },
                { label: "Quaterly", value: "quaterly" },
                { label: "Monthly", value: "monthly" },
                { label: "Yearly", value: "yearly" },
              ],
              Placeholder: "",
              validationRules: [
                {
                  required: true,
                  message: "Subscription Frequency is required",
                },
              ],
            },
          ],
          [
            {
              id: 11,
              label: "Discount:",
              type: "number",
              name: "discount",
              Placeholder: "",
            },
            {
              id: 12,
              label: "Subscriber Count:",
              type: "number",
              name: "subscriber_count",
              Placeholder: "",
            },
          ],
          [
            {
              id: 13,
              label: "Status:",
              type: "select",
              name: "status",
              options: [
                { label: "New", value: "new" },
                { label: "Lead", value: "lead" },
                { label: "Follow-up", value: "followup" },
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ],
              Placeholder: "",
              validationRules: [
                { required: true, message: "Status is required" },
              ],
            },

            // {
            //   id: 14,
            //   label: "Attach Client Contract:",
            //   type: "file",
            //   name: "client_contract_file",
            //   Placeholder: "",
            // },
          ],
          // [
          //   {
          //     id: 15,
          //     // label: "Cancel",
          //     type: "buttongroup",
          //     position: "end",
          //     textColor: "#2D53DA",
          //     event: "back",
          //     buttons: [
          //       {
          //         label: "Cancel",
          //         disabled: false,
          //         loading: false,
          //         btnStyle: ActionButtonStyle.cancelButton,
          //         onClick: () => {
          //           handleClickCancel();
          //         },
          //       },
          //       {
          //         label: "Next",
          //         disabled: false,
          //         loading: false,
          //         type: "submit",
          //         btnStyle: ActionButtonStyle.NextButton,
          //         onClick: () => {
          //           // handleSubmitForm()
          //         },
          //       },
          //     ],
          //   },
          // ],
        ],
      },
    ],
  };

  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    client_name: "",
    email_id: "",
    subscription_plan: "basic",
  });
  const stepdata = [
    // { id: 1, title: "Basic" },
    // { id: 2, title: "Features" },
  ];

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (value, name) => {
    console.log("Selected value:", value);
    console.log("Field name:", name);
    setData({
      ...data,
      [name]: value,
    });
  };

  const getMenuList = async () => {
    try {
      const API =
        "https://peo32t8k2i.execute-api.ap-south-1.amazonaws.com/dev/user/menulist";
      const response = await axios.get(API);
      setMenuList(response?.data?.response?.menulistjson);
    } catch (error) {}
  };
  useEffect(() => {
    getMenuList();
  }, []);

  const handlesubmit = async () => {
    try {
      const userauthregisterurl =
        "https://peo32t8k2i.execute-api.ap-south-1.amazonaws.com/dev/user/register";

      const payload = {
        companyName: data?.company_name,
        country: "",
        domain: "",
        email: data?.email_id,
        employeeCount: data?.employee_count,
        firstName: data?.client_name,
        interval: "",
        lastName: "",
        monthlyCost: 0,
        password: "12345678",
        paymentType: "free",
        productList: "",
        totalCost: 0,
        type: "company",
        subscription_plan: data?.subscription_plan,
        phoneNumber: data?.contact_number,
        usermenuList: menuList,
      };
      const response = await axios.post(userauthregisterurl, payload);
      if (
        response?.data?.status === 201 ||
        response?.data?.status === 200 ||
        response?.data?.statusCode === 201 ||
        response?.data?.statusCode === 200
      ) {
        setLoading(false);
      } else {
        setLoading(false);
        return false;
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setLoading(false);
      message.error(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleSubmitAddForm = async (e) => {
    setLoading(true);
    e.preventDefault();
    // Prepare the template parameters for EmailJS
    const templateParams = {
      client_name: data.client_name,
      //user_place: data.userPlace,
      email_id: data.email_id,
    };
    try {
      const response = await emailjs.send(
        "service_xxyiegl", // Replace with your EmailJS service ID
        "template_2phqm8u", // Replace with your EmailJS template ID
        templateParams,
        "m1IPM1dmTTYJqCFBi" // Replace with your EmailJS public key
      );
      console.log("response---->", response);
      if (response?.status === 200) {
        setLoading(false);
        await new Promise((resolve) => {
          setLoading(false);
          alert("Email sent successfully to the user!");
          resolve();
        });
        // alert("Email sent successfully to the user!");
        console.log("Success:", response.status, response.text);

        // setData({});
        // setStep(1); // Move to the next step if needed
        handlePostClientData();
      }
    } catch (error) {
      alert("Failed to send email. Please try again.");
      console.log("Error:", error);
      setLoading(false);
    }
  };

  const handlePostClientData = async () => {
    setLoading(true);
    handlesubmit();

    const url =
      "https://nhay2hvx12.execute-api.ap-northeast-1.amazonaws.com/Prod/addData";
    const obj = {
      client_name: data?.client_name,
      system_name: data?.Support,
      basic_plan: !!data?.basic_plan,
      advanced_plan: !!data?.advanced_plan,
      enterprise_plan: !!data?.enterprise_plan,
      status: data?.status,
      updated_date: new Date().toLocaleDateString("en-CA"),
      created_date: new Date().toLocaleDateString("en-CA"),
      updated_by: "",
      created_by: "",
      location: data?.location,
      email_id: data?.email_id,
      employee_count: data?.employee_count,
      subscription_plan: data?.subscription_plan,
      contact_person: data?.contact_person,
      contact_number: data?.contact_number,
      company_name: data?.company_name,
      password: data?.password,

      dashboard: !!data?.dashboard,
      checkinout_biometric: !!data?.checkinout_biometric,
      leaveapplication_portal: !!data?.leaveapplication_portal,
      calender_view: !!data?.calender_view,
      exception_report: !!data?.exception_report,
      support: !!data?.support,
      standard_reporting: !!data?.standard_reporting,

      hr_resources: !!data?.hr_resources,
      manage: !!data?.manage,
      shift_management: !!data?.shift_management,
      document_management: !!data?.document_management,
      my_teams: !!data?.my_teams,
      monthly_attandance_report: !!data?.monthly_attandance_report,
      asset_management: !!data?.asset_management,
      mobile_app: !!data?.mobile_app,
      recruitment_manaement: !!data?.recruitment_manaement,
      automated_onboarding: !!data?.automated_onboarding,
      apprisal_management: !!data?.apprisal_management,

      performance_management: !!data?.performance_management,
      travel_expenses: !!data?.travel_expenses,
      expenses_reimbursment: !!data?.expenses_reimbursment,
      disciplinary_management: !!data?.disciplinary_management,
      incident_reporting: !!data?.incident_reporting,
      simba_ai: !!data?.simba_ai,
      org_chart: !!data?.org_chart,
      activity_log: !!data?.activity_log,
      report_preference: !!data?.report_preference,
      integrateex_payroll: !!data?.integrateex_payroll,
      payroll: !!data?.payroll,

      amount: data?.amount,
      discount: data?.discount,
      subscriber_count: data?.subscriber_count,
      supscription_frequency: data?.supscription_frequency,
    };
    try {
      setLoading(true);
      const response = await axios.post(url, obj);
      if (
        response?.data?.status === 201 ||
        response?.data?.status === 200 ||
        response?.data?.statusCode === 201 ||
        response?.data?.statusCode === 200
      ) {
        setLoading(false);
        const newData = response.data.response;

        dispatch(setGetAllClientData([newData, ...getAllClientData]));
        navigate("/client");

        message.success("Client added successfully!");
      } else {
        setLoading(false);
        message.error("Failed to add client. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setLoading(false);
      message.error(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handledCheckboxChange = (checked, menu, submenu) => {
    setMenuList((prevMenuList) => {
      const updatedList = prevMenuList?.map((item) => {
        if (item?.id === menu) {
          if (submenu?.length > 0) {
            const updatedSubmenu = item?.subMenu?.map((subItem) => {
              if (subItem?.key === submenu) {
                return {
                  ...subItem,
                  permission: {
                    create: checked,
                    view: checked,
                    delete: checked,
                    edit: checked,
                  },
                  access: checked,
                };
              }
              return subItem;
            });
            const allSubMenuAccessFalse =
              updatedSubmenu?.length > 0 &&
              updatedSubmenu?.every((subItem) => subItem?.access === false);
            return {
              ...item,
              access: allSubMenuAccessFalse ? false : true,
              subMenu: updatedSubmenu,
            };
          } else {
            return {
              ...item,
              access: checked,
              permission: {
                create: checked,
                view: checked,
                delete: checked,
                edit: checked,
              },
            };
          }
        }

        return item;
      });
      return updatedList;
    });
  };

  useEffect(() => {
    setMenuList((prevMenuList) => {
      const updatedList = prevMenuList?.map((item) => {
        // Define allowed plan types based on subscription_plan
        const allowedPlans = ["basic"];
        if (data?.subscription_plan?.toLowerCase() === "lite") {
          allowedPlans.push("lite");
        } else if (data?.subscription_plan?.toLowerCase() === "pro") {
          allowedPlans.push("lite", "pro");
        }

        // Check if the item's planType matches any of the allowed plans
        const isAllowedPlan = allowedPlans?.includes(
          (item?.planType || "").toLowerCase()
        );

        if (item?.subMenu?.length > 0) {
          // Update subMenu items based on access
          const updatedSubmenu = item?.subMenu.map((subItem) => ({
            ...subItem,
            access: isAllowedPlan,
            permission: {
              create: isAllowedPlan,
              edit: isAllowedPlan,
              delete: isAllowedPlan,
              view: isAllowedPlan,
            },
          }));
          return {
            ...item,
            access: isAllowedPlan,
            subMenu: updatedSubmenu,
          };
        }

        // Update the parent item directly if no subMenu
        return {
          ...item,
          access: isAllowedPlan,
          permission: {
            create: isAllowedPlan,
            edit: isAllowedPlan,
            delete: isAllowedPlan,
            view: isAllowedPlan,
          },
        };
      });

      return updatedList;
    });
  }, [data]);

  const groupedData = {
    Basic: menuList?.filter((item) => item?.planType === "Basic"),
    Lite: menuList?.filter((item) => item?.planType === "Lite"),
    Pro: menuList?.filter((item) => item?.planType === "Pro"),
  };

  const renderPlanBox = (planType, menu) => (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "20px",
        backgroundColor: "#f9f9f9",
        flex: "1",
      }}
    >
      <h3 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>
        {planType} Plan
      </h3>
      {menu.map((item) => (
        <div key={item.key} style={{ marginBottom: "10px" }}>
          {item?.subMenu?.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                {item.title} {item?.subtitle}
              </span>
              <Checkbox
                name={item.name}
                checked={item?.access}
                style={{ width: "30px" }}
                onChange={(e) =>
                  handledCheckboxChange(e.target.checked, item.id, "")
                }
              />
            </div>
          )}
          {item?.subMenu?.length > 0 && (
            <>
              {item?.subMenu?.map((val) => (
                <div key={item.key} style={{ marginBottom: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {val.title} {val?.subtitle}
                    </span>
                    <Checkbox
                      name={val.name}
                      style={{ width: "30px" }}
                      checked={val?.access}
                      onChange={(e) =>
                        handledCheckboxChange(
                          e.target.checked,
                          item.id,
                          val.key
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      ))}
    </div>
  );
  const navigate = useNavigate();

  return (
    <StyledClientWrapper>
      {ispermission ? (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => setIsPermission(false)}
          ></label>{" "}
          <b className="pb-0 pt-1">Select Permission</b>
          <UITable data={tabledata} columns={columns} />
          <div className="text-right w-100 p-1">
            <ButtonFilled
              className="text-end logout-button"
              type="primary"
              onClick={() => setIsPermission(true)}
            >
              Create
            </ButtonFilled>
          </div>
        </>
      ) : (
        <>
          <div>
            <div>
              <h5 className="view_client_goback">
                <p onClick={() => navigate("/client")}>Client</p>{" "}
                <RightOutlined className="right_out_icon" />{" "}
                <storng>Adding Client</storng>{" "}
              </h5>
            </div>
            <StepsProgress stepdata={stepdata} title="Add Role:" step={step} />
            {step === 0 && (
              <>
                <GridBuilder
                  formStructureJson={addNewClientForm}
                  formSubmit={handleSubmitForm}
                  handleChange={handleFormChange}
                  handleSelectChange={handleSelectChange}
                  handleBack={handleClickCancel}
                  initialValues={data}
                />
              </>
            )}
            <br />
            <br />

            {step === 0 && (
              <>
                <div style={{ display: "flex", gap: "20px" }}>
                  {Object.entries(groupedData).map(([planType, data]) =>
                    renderPlanBox(planType, data)
                  )}
                </div>

                <div className="text-right w-100 form_button_section">
                  <ButtonFilled
                    className={loading ? "custom-style-btn" : "logout-button"}
                    type="primary"
                    disabled={loading}
                    onClick={handleSubmitAddForm}
                  >
                    {loading ? <Spin /> : "Create"}
                  </ButtonFilled>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </StyledClientWrapper>
  );
};

export default AddNewClient;
