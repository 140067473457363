import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuItem: [
    {
      access: true,
      active: true,
      icon: "home",
      key: "home",
      path: "dashboard",
      permission: {
        create: true,
        delete: true,
        edit: true,
        view: true,
      },
      subMenu: [
        {
          access: true,
          icon: "dashboard",
          key: "dashboard",
          path: "dashboard",
          permission: {
            create: true,
            view: true,
            delete: true,
            edit: true,
          },
          title: "Dashboard",
        },
        {
          access: true,
          active: false,
          icon: "people",
          key: "clients",
          path: "client",
          permission: {
            create: true,
            view: true,
            delete: true,
            edit: true,
          },
          subMenu: [],
          tabs: [
            { name: "Tab 1", content: "Content for Tab 1" },
            { name: "Tab 2", content: "Content for Tab 2" },
          ],
          title: "Clients",
        },
      ],
      title: "Home",
    },
  ],

  activeMenuList: [
    {
      access: true,
      icon: "dashboard",
      key: "dashboard",
      path: "dashboard",
      permission: {
        create: true,
        view: true,
        delete: true,
        edit: true,
      },
      title: "Dashboard",
    },
    {
      access: true,
      active: false,
      icon: "people",
      key: "clients",
      path: "client",
      permission: {
        create: true,
        view: true,
        delete: true,
        edit: true,
      },
      subMenu: [],
      tabs: [
        { name: "Tab 1", content: "Content for Tab 1" },
        { name: "Tab 2", content: "Content for Tab 2" },
      ],
      title: "Clients",
    },
  ],
  isSideBarCollapsed: false,
  addGroupData: [],
  usersList: [],
  editUserData: {},
  editGroupData: {},
  initialLoader: true,
  getAllClientData: [],
  editClientData: [],
};

export const systemSlice = createSlice({
  name: "systemSlice",
  initialState,
  reducers: {
    setInitialLoader: (state, action) => {
      return {
        ...state,
        initialLoader: action.payload,
      };
    },
    setGetAllClientData: (state, action) => {
      return {
        ...state,
        getAllClientData: action.payload,
      };
    },
    setUsersList: (state, action) => {
      return {
        ...state,
        usersList: action.payload,
      };
    },
    setMenuItemData: (state, action) => {
      return {
        ...state,
        menuItem: action.payload,
      };
    },

    setActiveMenuList: (state, action) => {
      return {
        ...state,
        activeMenuList: action.payload,
      };
    },

    setIsSideBarCollapsed: (state, action) => {
      return {
        ...state,
        isSideBarCollapsed: action.payload,
      };
    },
    setEditUserData: (state, action) => {
      return {
        ...state,
        editUserData: action.payload,
      };
    },
    setEditGroupData: (state, action) => {
      return {
        ...state,
        editGroupData: action.payload,
      };
    },
    setAddGroupData: (state, action) => {
      return {
        ...state,
        addGroupData: action.payload,
      };
    },
    setEditClientData: (state, action) => {
      return {
        ...state,
        editClientData: action.payload,
      };
    },
  },
});

export const {
  setInitialLoader,
  setGetAllClientData,
  setMenuItemData,
  setActiveMenuList,
  setIsSideBarCollapsed,
  setEditUserData,
  setAddGroupData,
  setUsersList,
  setEditGroupData,
  setEditClientData
} = systemSlice.actions;

export const systemReducer = systemSlice.reducer;
