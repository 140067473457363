import "./App.css";
import React from "react";
import UIRoutes from "./Routes/Routes";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <UIRoutes />
      </QueryClientProvider>
    </div>
  );
}

export default App;
