import styled from "styled-components";

const StyledClientWrapper = styled.div`
    .client_totle_count{
        text-wrap: nowrap;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.83px;
        text-align: left;
    }
    .client_details_container{
    }
    .client_details_wrapper{
        padding: 16px;
        border-radius: 8px;
        background: #FAFAFA;
    }
    .view_client_goback{
        display: flex;
        align-items: center;
        gap: 10px;
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 20.83px;
            text-align: left;
            margin-bottom: 0px;
            cursor: pointer;
        }
        .right_out_icon{
            font-size: 16px;
            // font-weight: 400;
        }
        storng{
            font-size: 16px;
        }
    }
    .form_button_section{
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: end;
        margin-top: 12px;
    }
    .table_action_icons{
        display: flex;
        gap: 16px;
    }
    .actionicon_style{
        font-size: 20px;
        color: #2D53DA;
        cursor: pointer;
    }
    .client_details_data_wrap{
        min-width: 100%;
        max-width: max-content;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .client_details_ans{
            min-width: 60%;
            max-width: max-content;
        }
    }
    .client_detail_indigators{
        display: flex;
        justify-content: end;
        gap: 2rem;
    }
    .client_subscription_type{
        min-width: 75px;
        height: 24px;
        background: #FFFFFF;
        color: #03C83E;
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid #03C83E;
        border-radius: 6px;
        .circle_icon{
            width: 8px;
            height: 8px;
            background: #03C83E;
            border-radius: 50%;
        }
    }
    
    .cancel_button_style{
        width: 130px;
        height: 34px;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #2D53DA;
        color: #2D53DA;
    }

    .delete_button_style{
        width: 130px;
        height: 34px;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #2D53DA;
        color: #fff;
        background: #2D53DA;
        outline: none !important;
    }
    .delete_button_style:hover {
        background: #2D53DA !important;
        color: #fff !important;
    }
    .delete_button_style:focus,
    .delete_button_style:active {
        background: #2D53DA;
    }
    
    .client_status_type{
        min-width: 75px;
        height: 24px;
        background: #FFFFFF;
        color: #03C83E;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid red;
        border-radius: 6px;
        .active_button{
            margin-bottom: 0px;
        }
        .inactive_button{
            margin-bottom: 0px;
            color: red;
        }
    }
    .create_button_style {
        outline: none !important;
    
    }
    .create_button_style:hover {
        background-color: #2D53DA !important;
    }
    .create_button_style1 {
    border: 1px solid red;
    }

    .custom-style-btn{
        width: 120px;
        background-color: unset !important;
        border: 1px solid #2D53DA;
        &:hover{
            background-color: unset !important;
            border: 1px solid #2D53DA;
        }
        
    }
    


    

`;

export default StyledClientWrapper;