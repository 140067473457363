import React, { useState, useEffect } from "react";
import styled from "styled-components";
import GridBuilder from "../../UIBuilderEngine/GridBuilder";
import { ButtonFilled } from "../../reusableComponent/UIButtons";
import { Button, Form, message, Spin, Switch, Table, Checkbox } from "antd";
import UITable from "../../reusableComponent/UITable/UITable";
import { RightOutlined } from "@ant-design/icons";
import StyledClientWrapper from "./style";
import COLORS from "../../Common/Colors";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setGetAllClientData } from "../../Redux/systemSlice";
import { useDispatch, useSelector } from "react-redux";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #00000024;
    border-radius: 10px;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .ant-table-thead > tr > th::before {
    display: none !important;
  }
  .ant-table-cell-row-hover {
    background: #e3e8fc !important;
  }
`;
const AddClientWrap = styled.div`
  h6 {
    color: #636980;
  }
`;

const EditClient = ({ setAddNew }) => {
  const getAllClientData = useSelector(
    (state) => state.systemTracking.getAllClientData
  );
  const editClientData = useSelector(
    (state) => state.systemTracking.editClientData
  );


  // const dataSource = [
  //   {
  //     key: 1,
  //     clientName: "Dashboard (Mine, My Team, HR)",
  //     name: "dashboard",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: editClientData?.dashboard,
  //   },
  //   {
  //     key: 2,
  //     clientName: "Check in and check out through Biometrics",
  //     name: "checkinout_biometric",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: editClientData?.checkinout_biometric,
  //   },
  //   {
  //     key: 3,
  //     clientName: "Leave application through portal",
  //     name: "leaveapplication_portal",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: editClientData?.leaveapplication_portal,
  //   },
  //   {
  //     key: 4,
  //     clientName: "Calendar view (Mine, Team, All)",
  //     name: "calender_view",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: editClientData?.calender_view,
  //   },
  //   {
  //     key: 5,
  //     clientName: "Standard Reporting (Daily Attendance & Leave Report)",
  //     name: "standard_reporting",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: editClientData?.calender_view,
  //   },
  //   {
  //     key: 6,
  //     clientName:
  //       "Exception Reports (Late check-in, Early Check out, Absent Report)",
  //     name: "exception_report",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: editClientData?.exception_report,
  //   },
  //   {
  //     key: 7,
  //     clientName: "Support",
  //     name: "support",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: editClientData?.support,
  //   },
  //   {
  //     key: 8,
  //     clientName: "HR Resources (People, Policies)",
  //     name: "hr_resources",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: editClientData?.hr_resources,
  //   },
  //   {
  //     key: 9,
  //     clientName: "Manage",
  //     name: "manage",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: editClientData?.manage,
  //   },
  //   {
  //     key: 10,
  //     clientName: "Shift Management",
  //     name: "shift_management",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: editClientData?.shift_management,
  //   },
  //   {
  //     key: 11,
  //     clientName: "Document Management",
  //     name: "document_management",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: editClientData?.document_management,
  //   },
  //   {
  //     key: 12,
  //     clientName: "My Team",
  //     name: "my_teams",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: editClientData?.my_teams,
  //   },
  //   {
  //     key: 13,
  //     clientName: "Monthly Attendance Reports",
  //     name: "monthly_attandance_report",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: editClientData?.monthly_attandance_report,
  //   },
  //   {
  //     key: 14,
  //     clientName: "Asset Management",
  //     name: "asset_management",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: editClientData?.asset_management,
  //   },
  //   {
  //     key: 15,
  //     clientName: "Mobile App",
  //     name: "mobile_app",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: editClientData?.mobile_app,
  //   },
  //   {
  //     key: 16,
  //     clientName: "Recruitment Management",
  //     name: "recruitment_manaement",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.recruitment_manaement,
  //   },
  //   {
  //     key: 17,
  //     clientName: "Automated Onboarding",
  //     name: "automated_onboarding",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.automated_onboarding,
  //   },
  //   {
  //     key: 18,
  //     clientName: "Appraisal Management",
  //     name: "apprisal_management",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.apprisal_management,
  //   },
  //   {
  //     key: 19,
  //     clientName: "Performance Management",
  //     name: "performance_management",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.performance_management,
  //   },
  //   {
  //     key: 20,
  //     clientName: "Travel/ Expense Approvals Management",
  //     name: "travel_expenses",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.travel_expenses,
  //   },
  //   {
  //     key: 21,
  //     clientName: "Expense Reimbursement",
  //     name: "expenses_reimbursment",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.expenses_reimbursment,
  //   },
  //   {
  //     key: 22,
  //     clientName: "Disciplinary Management",
  //     name: "disciplinary_management",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.disciplinary_management,
  //   },
  //   {
  //     key: 23,
  //     clientName: "Incident Reporting",
  //     name: "incident_reporting",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.incident_reporting,
  //   },
  //   {
  //     key: 24,
  //     clientName: "Simba AI",
  //     name: "simba_ai",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.simba_ai,
  //   },
  //   {
  //     key: 25,
  //     clientName: "Org Chart",
  //     name: "org_chart",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.org_chart,
  //   },
  //   {
  //     key: 26,
  //     clientName: "Activity Log",
  //     name: "activity_log",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.activity_log,
  //   },
  //   {
  //     key: 27,
  //     clientName: "Report Preferences",
  //     name: "report_preference",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.report_preference,
  //   },
  //   {
  //     key: 28,
  //     clientName: "Integration with existing Payroll system",
  //     name: "integrateex_payroll",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.integrateex_payroll,
  //   },
  //   {
  //     key: 29,
  //     clientName: "Payroll",
  //     name: "payroll",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: editClientData?.payroll,
  //   },
  // ];
  const dataSource=
    [
    
      {
            key: 1,
            clientName: "Dashboard - Mine",
            name: "dashboard",
            access: editClientData?.dashboard,  
            planType: "Basic"   
      },
      {
            key: 2,
            clientName: "Check in and check out through Biometrics",
            name: "checkinout_biometric",
            access: editClientData?.checkinout_biometric,
            planType: "Basic"  
          },
          {
            key: 3,
            clientName: "Leave application through portal",
            name: "leaveapplication_portal",
            access: editClientData?.leaveapplication_portal,
            planType: "Basic"  
          },
          {
            key: 4,
            clientName: "Calendar view - Mine",
            name: "calender_view",
            access: editClientData?.calender_view,
            planType: "Basic"  

          },
          {
            key: 5,
            clientName: "Calendar view - Team",
            name: "calender_view",
            access: editClientData?.calender_view,
            planType: "Basic"  

          },
          {
            key: 6,
            clientName: "Calendar view - All",
            name: "calender_view",
            access: editClientData?.calender_view,
            planType: "Basic"  

          },
          {
            key: 7,
            clientName: "Standard Reporting (Daily Attendance & Leave Report)",
            name: "standard_reporting",
            access: editClientData?.calender_view,
            planType: "Basic"  

          },
          {
            key: 8,
            clientName:
              "Exception Reports - Late check-in ",
            name: "exception_report",
            access: editClientData?.exception_report,
            planType: "Basic"  

          },
          {
            key: 9,
            clientName:
              "Exception Reports - Early Check out",
            name: "exception_report",
            access: editClientData?.exception_report,
            planType: "Basic"  

          },
          {
            key: 10,
            clientName:
              "Exception Reports - Absent Report",
            name: "exception_report",
            access: editClientData?.exception_report,
            planType: "Basic"  

          },
          {
            key: 11,
            clientName: "Support",
            name: "support",
            access: editClientData?.support,
            planType: "Basic"  

          },
          {
            key: 12,
            clientName: "HR Resources - Policies",
            name: "hr_resources",
            access: editClientData?.hr_resources,
            planType: "Lite"  

          },
          {
            key: 13,
            clientName: "HR Resources - People",
            name: "hr_resources",
            access: editClientData?.hr_resources,
            planType: "Lite"  

          },
          {
            key: 14,
            clientName: "Manage",
            name: "manage",
            access: editClientData?.manage,
            planType: "Lite"  

          },
          {
            key: 15,
            clientName: "Shift Management",
            name: "shift_management",
            access: editClientData?.shift_management,
            planType: "Lite"  

          },
       
        {
              key: 16,
              clientName: "Document Management",
              name: "document_management",
             
              access: editClientData?.document_management,
              planType: "Lite"
            },
            {
              key: 17,
              clientName: "Dashboard - My Team",
              name: "dashboard",
              
              access: editClientData?.my_teams,
              planType: "Lite"
            },
            {
              key: 18,
              clientName: "Monthly Attendance Reports",
              name: "monthly_attandance_report",
              
              access: editClientData?.monthly_attandance_report,
              planType: "Lite"
            },
            {
              key: 19,
              clientName: "Asset Management",
              name: "asset_management",
             
              access: editClientData?.asset_management,
              planType: "Lite"
            },
            {
              key: 20,
              clientName: "Mobile App",
              name: "mobile_app",
             
              access: editClientData?.mobile_app,
              planType: "Lite"
            },
            {
              key: 21,
              clientName: "Recruitment Management",
              name: "recruitment_manaement",
              
              access: editClientData?.recruitment_manaement,
              planType: "Lite"
            },
            {
              key: 22,
              clientName: "Automated Onboarding",
              name: "automated_onboarding",
             
              access: editClientData?.automated_onboarding,
              planType: "Lite"
            },
            {
              key: 23,
              clientName: "Appraisal Management",
              name: "apprisal_management",
             
              access: editClientData?.apprisal_management,
              planType: "Lite"
            },
            {
              key: 24,
              clientName: "Dashboard - HR",
              name: "dashboard",
              access: editClientData?.dashboard_hr,  
              planType: "Pro"   
        },
            {
              key: 25,
              clientName: "Performance Management",
              name: "performance_management",
              
              access: editClientData?.performance_management,
              planType: "Pro"
            },
            {
              key: 26,
              clientName: "Travel/ Expense Approvals Management",
              name: "travel_expenses",
              
              access: editClientData?.travel_expenses,
              planType: "Pro"
            },
       
       
            {
              key: 27,
              clientName: "Expense Reimbursement",
              name: "expenses_reimbursment",
             
              access: editClientData?.expenses_reimbursment,
              planType: "Pro"

            },
            {
              key: 28,
              clientName: "Disciplinary Management",
              name: "disciplinary_management",
              
              access: editClientData?.disciplinary_management,
              planType: "Pro"

            },
            {
              key: 29,
              clientName: "Incident Reporting",
              name: "incident_reporting",
              access: editClientData?.incident_reporting,
              planType: "Pro"

            },
            {
              key: 30,
              clientName: "Simba AI",
              name: "simba_ai",
              access: editClientData?.simba_ai,
              planType: "Pro"

            },
            {
              key: 31,
              clientName: "Org Chart",
              name: "org_chart",
              access: editClientData?.org_chart,
              planType: "Pro"

            },
            {
              key: 32,
              clientName: "Activity Log",
              name: "activity_log",
              access: editClientData?.activity_log,
              planType: "Pro"

            },
            {
              key: 33,
              clientName: "Report Preferences",
              name: "report_preference",
              access: editClientData?.report_preference,
              planType: "Pro"

            },
            {
              key: 34,
              clientName: "Integration with existing Payroll system",
              name: "integrateex_payroll",
              access: editClientData?.integrateex_payroll,
              planType: "Pro"

            },
            {
              key: 35,
              clientName: "Payroll",
              name: "payroll",
              access: editClientData?.payroll,
              planType: "Pro"
            },
       ] 

  const [countries, setCountries] = useState([]);

  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [ispermission, setIsPermission] = useState(false);
  const [clientPermissionData, setClientPermissionData] = useState([]);
  useEffect(() => {
    // Set initial permissions based on editClientData
    const initialPermissions = dataSource.map(item => ({
        ...item,
        access: editClientData[item.name] || false// Assuming editClientData has the same structure
    }));
    setClientPermissionData(initialPermissions);
}, [editClientData]);
  const handleCheckboxChange = (item, checked) => {
   
    setClientPermissionData((prevData) =>
       prevData.map(data => (data.key === item.key ? { ...data, access: checked } : data))
    
    
  );
};
const renderPlanBox = (planType, data) => (
  <div
    key={planType}
    style={{
      border: "1px solid #ccc",
      borderRadius: "8px",
      padding: "20px",
      marginBottom: "20px",
      backgroundColor: "#f9f9f9",
      flex:"1",
    }}
  >
    <h3 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>
        {planType} Plan
      </h3>
      {data.map((item) => (
        <div key={item.key} style={{ marginBottom: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>{item.clientName}</span>
          <Checkbox
           name={item.name}
      style={{ width: "30px" }}
      checked={item.access}
      onChange={(e) => handleCheckboxChange(item, e.target.checked)} 
      />
          </div>
        </div>
      ))}
      </div>
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const tabledata = [
    {
      id: 1,
      featurename: "Dashboard(Mine, My Team, HR)",
      // pricing: "-",
      subscription: "Basic | Lite | Pro",
    },
    {
      id: 2,
      featurename: "Check in and Check out through Biometrics",
      // pricing: "-",
      subscription: "Lite | Pro",
    },
    {
      id: 3,
      featurename: "Leave application through portal",
      // pricing: "-",
      subscription: "Lite",
    },
  ];

  const ActionButtonStyle = {
    cancelButton: {
      color: "#2D53DA !important",
      border: "1px solid #2D53DA",
      width: "130px",
      height: "34px",
      padding: "6.5px",
      borderRadius: "8px",
      background: "unset !important", // !important is not supported here
    },
    NextButton: {
      border: "1px solid #2D53DA",
      width: "130px",
      height: "34px",
      padding: "6.5px",
      borderRadius: "8px",
      color: "#fff !important",
    },
  };

  const columns = [
    {
      title: "Feature name",
      dataIndex: "featurename",
      key: "featurename",
      width: "25%",
    },
    // {
    //   title: "Pricing",
    //   dataIndex: "pricing",
    //   key: "pricing",
    //   width: "25%",
    // },
    // {
    //   title: "Available in Subscription Model",
    //   dataIndex: "subscription",
    //   key: "subscription",
    //   width: "25%",
    // },
    {
      title: "Access",
      dataIndex: "action",
      key: "action",
      width: "25%",
      render: (action, record) => {
        return <Switch
        className="switch_button"
        defaultChecked
        name={record.clientName}
         size="small"
         checked={record.access}
        onChange={(checked) => handleSwitchChange(record, checked)}
          />;
      },
    },
  ];

  const handleSubmitForm = async (e) => {
    try {
      await form.validateFields();
      setStep(2);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleClickCancel = () => {
    navigate("/client");
  };

  const SubcriptionPlanData = [
    { value: "basic", label: "Basic" },
    { value: "lite", label: "Lite" },
    { value: "pro", label: "Pro" },
  ];

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryOptions = response.data.map(country => ({
          label: country.name.common, // Display name
          value: country.cca2 // Country code as value
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountries();
  }, []);

  const addNewClientForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [],
        fields: [
          [
            {
              id: 1,
              label: "Company Name:",
              type: "text",
              name: "company_name",
              Placeholder: "",
              validationRules: [
                { required: true, message: "Company name is required" },
                {
                  min: 3,
                  max: 25,
                  message: "Company name must be between 3 and 25 characters",
                },
                {
                  pattern: /^[a-zA-Z\s]*$/,
                  message:
                    "Company name cannot contain numbers or special characters",
                },
              ],
            },
            {
              id: 2,
              label: "Client Name:",
              type: "text",
              name: "client_name",
              Placeholder: "",
              validationRules: [
                { required: true, message: "Client name is required" },
                {
                  min: 3,
                  max: 25,
                  message: "Client name must be between 3 and 25 characters",
                },
                {
                  pattern: /^[a-zA-Z\s]*$/,
                  message:
                    "Client name cannot contain numbers or special characters",
                },
              ],
            },
          ],
          [
            {
              id: 3,
              label: "Location:",
              type: "select",
              name: "location",
              options: countries,
              Placeholder: "",
              validationRules: [
                { required: true, message: "Location is required" },
              ],
            },
            {
              id: 4,
              label: "Employee Count / Company Size:",
              type: "text",
              name: "employee_count",
              Placeholder: "",
              validationRules: [
                { required: true, message: "Employee Count is required" },
                {
                  pattern: /^[0-9]{1,4}$/,
                  message:
                    "Employee Count must be a number less than or equal to 4 characters",
                },
              ],
            },
          ],
          [
            {
              id: 5,
              label: "Contact Person:",
              type: "text",
              name: "contact_person",
              Placeholder: "",
              validationRules: [
                {
                  required: true,
                  message: "Contact Person is required",
                },
                {
                  min: 3,
                  max: 30,
                  message: "Contact Person must be between 3 and 30 characters",
                },
                {
                  pattern: /^[a-zA-Z\s]*$/,
                  message:
                    "Contact Person cannot contain numbers or special characters",
                },
              ],
            },
            {
              id: 6,
              label: "Contact Number:",
              type: "phone",
              name: "contact_number",
              maxLength: 20,
              Placeholder: "",
              validationRules: [
                { required: true, message: "Contact Number is required" },
                {
                  min: 1,
                  max: 20,
                },
                {
                  pattern: /^[0-9]{10,20}$/,
                  message: "Contact number must be between 10 to 20 digits",
                },
              ],
            },
          ],
          [
            {
              id: 7,
              label: "Email ID:",
              type: "email",
              name: "email_id",
              Placeholder: "",
              validationRules: [
                { required: true, message: "email is required" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ],
            },
            {
              id: 8,
              label: "Amount ($):",
              type: "number",
              name: "amount",
              Placeholder: "",
              validationRules: [
                { required: true, message: "Amount is required" },
                {
                  pattern: /^[0-9]{1,10}$/,
                  message:
                    "Amount must be a number less than or equal to 10 characters",
                },
              ],
            },
            // {
            //   id: 8,
            //   label: "Password:",
            //   type: "password",
            //   name: "password",
            //   Placeholder: "",
            // }
          ],
          [
            {
              id: 9,
              label: "Subscription Plan:",
              type: "select",
              name: "subscription_plan",
              options: SubcriptionPlanData,
              Placeholder: "",
              validationRules: [
                { required: true, message: "Subscription Plan is required" },
              ],
            },
            {
              id: 10,
              label: "Subscription Frequency:",
              type: "select",
              name: "supscription_frequency",
              options: [
                { label: "Bi-annually", value: "biannually" },
                { label: "Quaterly", value: "quaterly" },
                { label: "Monthly", value: "monthly" },
                { label: "Yearly", value: "yearly" },
              ],
              Placeholder: "",
              validationRules: [
                {
                  required: true,
                  message: "Subscription Frequency is required",
                },
              ],
            },
          ],
          [
            {
              id: 11,
              label: "Discount:",
              type: "number",
              name: "discount",
              Placeholder: "",
            },
            {
              id: 12,
              label: "Subscriber Count:",
              type: "number",
              name: "subscriber_count",
              Placeholder: "",
            },
          ],
          [
            {
              id: 13,
              label: "Status:",
              type: "select",
              name: "status",
              options: [
                { label: "New", value: "new" },
                { label: "Lead", value: "lead" },
                { label: "Follow-up", value: "followup" },
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ],
              Placeholder: "",
              validationRules: [
                { required: true, message: "Status is required" },
              ],
            },
            {},
            // {
            //   id: 14,
            //   label: "Attach Client Contract:",
            //   type: "file",
            //   name: "client_contract_file",
            //   Placeholder: "",
            // },
          ],
          // [
          //   {
          //     id: 15,
          //     // label: "Cancel",
          //     type: "buttongroup",
          //     position: "end",
          //     textColor: "#2D53DA",
          //     event: "back",
          //     buttons: [
          //       {
                //   label: "Cancel",
                //   disabled: false,
                //   loading: false,
                //   btnStyle: ActionButtonStyle.cancelButton,
                //   onClick: () => {
                //     handleClickCancel();
                //   },
                // },
                // {
            //       label: "Next",
            //       disabled: false,
            //       loading: false,
            //       type: "submit",
            //       btnStyle: ActionButtonStyle.NextButton,
            //       onClick: () => {
            //         // handleSubmitForm()
            //       },
            //     },
            //   ],
            // },
          // ],
        ],
      },
    ],
  };

  // const stepdata = [
  //   { id: 1, title: "Basic" },
  //   { id: 2, title: "Feautures" },
  // ];

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (value, name) => {
    console.log("Selected value:", value);
    console.log("Field name:", name);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSwitchChange = (record, checked) => {
    
    setClientPermissionData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.key === record.key ? { ...item, access: checked } : item
      );
      return updatedData;
    });
    setData({
      ...data,
      [record.name]: checked,
    });
  };

  const handleSubmitEditForm = async () => {
    setLoading(true);
    const url =
      "https://nhay2hvx12.execute-api.ap-northeast-1.amazonaws.com/Prod/updateData";
    const obj = {
      client_name: data?.client_name || editClientData?.client_name,
      system_name: data?.Support || editClientData?.Support,
      basic_plan: data?.basic_plan || editClientData?.basic_plan,
      advanced_plan: data?.advanced_plan || editClientData?.advanced_plan,
      enterprise_plan: data?.enterprise_plan || editClientData?.enterprise_plan,
      status: data?.status || editClientData?.status,
      updated_date: new Date().toLocaleDateString("en-CA"),
      created_date: new Date().toLocaleDateString("en-CA"),
      updated_by: "",
      created_by: "",
      location: data?.location || editClientData?.location,
      email_id: data?.email_id || editClientData?.email_id,
      employee_count: data?.employee_count || editClientData?.employee_count,
      subscription_plan:
        data?.subscription_plan || editClientData?.subscription_plan,
      contact_person: data?.contact_person || editClientData?.contact_person,
      contact_number: data?.contact_number || editClientData?.contact_number,
      company_name: data?.company_name || editClientData?.company_name,
      password: data?.password || editClientData?.password,
      dashboard: clientPermissionData.find(item => item.name === "dashboard")?.access ?? editClientData?.dashboard ?? false,
      checkinout_biometric: clientPermissionData.find(item => item.name === "checkinout_biometric")?.access ?? editClientData?.checkinout_biometric ?? false,
      leaveapplication_portal: clientPermissionData.find(item => item.name === "leaveapplication_portal")?.access ?? editClientData?.leaveapplication_portal ?? false,
      // dashboard: data?.dashboard,
      // checkinout_biometric: data?.checkinout_biometric,
      // leaveapplication_portal: data?.leaveapplication_portal,
      // calender_view: data?.calender_view,
      // support: data?.support,
      // standard_reporting: data?.standard_reporting,
      // exception_report: data?.exception_report,
      // hr_resources: data?.hr_resources,
      calender_view: clientPermissionData?.find(item => item.name === "calender_view")?.access ??editClientData?.calender_view??false,
      support: clientPermissionData?.find(item => item.name === "support")?.access ??editClientData?.support??false,
      standard_reporting: clientPermissionData?.find(item => item.name === "standard_reporting")?.access ??editClientData?.standard_reporting??false,
      exception_report: clientPermissionData?.find(item => item.name === "exception_report")?.access ??editClientData?.exception_report??false,
      hr_resources: clientPermissionData?.find(item => item.name === "hr_resources")?.access ??editClientData?.hr_resources??false,
      manage: clientPermissionData?.find(item => item.name === "manage")?.access ??editClientData?.manage??false,
      shift_management: clientPermissionData?.find(item => item.name === "shift_management")?.access ??editClientData?.shift_management??false,
      document_management: clientPermissionData?.find(item => item.name === "document_management")?.access ??editClientData?.document_management??false,
      my_teams: clientPermissionData?.find(item => item.name === "my_teams")?.access ??editClientData?.my_teams??false,
      monthly_attandance_report: clientPermissionData?.find(item => item.name === "monthly_attandance_report")?.access ??editClientData?.monthly_attandance_report??false,
      asset_management: clientPermissionData?.find(item => item.name === "asset_management")?.access ??editClientData?.asset_management??false,
      mobile_app: clientPermissionData?.find(item => item.name === "mobile_app")?.access ??editClientData?.mobile_app??false,
      recruitment_manaement: clientPermissionData?.find(item => item.name === "recruitment_manaement")?.access ??editClientData?.recruitment_manaement??false,
      automated_onboarding: clientPermissionData?.find(item => item.name === "automated_onboarding")?.access ??editClientData?.automated_onboarding??false,
      apprisal_management: clientPermissionData?.find(item => item.name === "apprisal_management")?.access ??editClientData?.apprisal_management??false,
      performance_management: clientPermissionData?.find(item => item.name === "performance_management")?.access ??editClientData?.performance_management??false,
      travel_expenses: clientPermissionData?.find(item => item.name === "travel_expenses")?.access ??editClientData?.travel_expenses??false,
      expenses_reimbursment: clientPermissionData?.find(item => item.name === "expenses_reimbursment")?.access ??editClientData?.expenses_reimbursment??false,
      disciplinary_management: clientPermissionData?.find(item => item.name === "disciplinary_management")?.access ??editClientData?.disciplinary_management??false,
      incident_reporting: clientPermissionData?.find(item => item.name === "incident_reporting")?.access ??editClientData?.incident_reporting??false,
      simba_ai: clientPermissionData?.find(item => item.name === "simba_ai")?.access ??editClientData?.simba_ai??false,
      org_chart: clientPermissionData?.find(item => item.name === "org_chart")?.access ??editClientData?.org_chart??false,
      activity_log: clientPermissionData?.find(item => item.name === "activity_log")?.access ??editClientData?.activity_log??false,
      report_preference: clientPermissionData?.find(item => item.name === "report_preference")?.access ??editClientData?.report_preference??false,
      integrateex_payroll: clientPermissionData?.find(item => item.name === "integrateex_payroll")?.access ??editClientData?.integrateex_payroll??false,
      payroll: clientPermissionData?.find(item => item.name === "payroll")?.access ??editClientData?. payroll??false,
      amount: data?.amount || editClientData?.amount,
      discount: data?.discount || editClientData?.discount,
      subscriber_count:
        data?.subscriber_count || editClientData?.subscriber_count,
      supscription_frequency:
        data?.supscription_frequency || editClientData?.supscription_frequency,
      permissions: clientPermissionData.reduce((acc, item) => {
        acc[item.name] = item.access; // Collect all permissions
        return acc;
      }, {}),
      
    };
    try {
      const response = await axios.patch(`${url}/${editClientData?.id}`, obj);
      if (
        response?.data?.status === 201 ||
        response?.data?.status === 200 ||
        response?.data?.statusCode === 201 ||
        response?.data?.statusCode === 200
      ) {
        setLoading(false);
        const newData = response.data.response;
        const updatedData = getAllClientData.map((item) =>
          item.id === editClientData?.id ? { ...item, ...newData } : item
        );
        dispatch(setGetAllClientData(updatedData));
        message.success("Edited successfully!");
        navigate("/client");
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setLoading(false);
      message.error("Failed to edit. Please try again.");
    }
  };

  const columns1 = [
    {
      title: "Feauture Name",
      dataIndex: "clientName",
      key: "clientName",
    },
    // {
    //   title: "Pricing",
    //   dataIndex: "pricing",
    //   key: "pricing",
    //   render: (text) => (text === "" ? "--" : text),
    // },
    {
      title: "Available In",
      dataIndex: "availableIn",
      key: "availableIn",
    },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      render: (text, record) => (
        <Switch
          className="switch_button"
          defaultChecked
          name={record.clientName}
          size="small"
          checked={record.access}
          onChange={(checked) => handleSwitchChange(record, checked)}
        />
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Basic",
      children: "",
    },
    {
      key: "2",
      label: "Features",
      children: "",
    },
  ];

  const onChangeTabs = (key) => {
    if (key === "1") {
      setStep(1);
    } else if (key === "2") {
      setStep(2);
    }
  };
  const groupedData = {
    Basic: clientPermissionData.filter((item) => item.planType === "Basic"),
    Lite: clientPermissionData.filter((item) => item.planType === "Lite"),

    Pro: clientPermissionData.filter((item) => item.planType === "Pro"),

  };
  return (
    <StyledClientWrapper>
      {ispermission ? (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => setIsPermission(false)}
          ></label>{" "}
          <b className="pb-0 pt-1">Select Permission</b>
          <UITable data={tabledata} columns={columns} />
          <div className="text-right w-100 p-1">
            <ButtonFilled
              className="text-end"
              type="primary"
              onClick={() => setIsPermission(true)}
            >
              Create
            </ButtonFilled>
          </div>
        </>
      ) : (
        <>
          <div>
            <div>
              <h5 className="view_client_goback">
                <p onClick={() => navigate("/client")}>Client</p>{" "}
                <RightOutlined className="right_out_icon" />{" "}
                <storng>Editing Client</storng>{" "}
              </h5>
            </div>
            {/* <StepsProgress stepdata={stepdata} title="Add Role:" step={step} /> */}
            {/* <div>
                <Tabs defaultActiveKey="1" items={items} onChange={onChangeTabs} />
            </div> */}
            {step === 1 && (
              <>
                <GridBuilder
                  formStructureJson={addNewClientForm}
                  formSubmit={handleSubmitForm}
                  handleChange={handleFormChange}
                  handleSelectChange={handleSelectChange}
                  handleBack={handleClickCancel}
                  initialValues={editClientData}
                />
              </>
            )}
            <br/><br/>
            {step === 1 && (
              <>
                {/* <StyledTable
                  dataSource={clientPermissionData}
                  columns={columns1}
                  rowClassName={() => "custom-row"}
                  pagination={false}
                /> */}
                <div style={{ display: "flex", gap: "20px" }}>
    {Object.entries(groupedData).map(([planType, data]) => renderPlanBox(planType, data))}
  </div>

                <div className="text-right w-100 form_button_section">
                  <Button className="text-end" onClick={() => setStep(1)}>
                    Back
                  </Button>
                  <ButtonFilled
                    className="text-end"
                    type="primary"
                    disabled={loading}
                    onClick={handleSubmitEditForm}
                  >
                    {loading ? <Spin /> : "Save"}
                  </ButtonFilled>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </StyledClientWrapper>
  );
};

export default EditClient;
