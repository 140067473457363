import React, { useState } from "react";
import report1 from "../client/detailstyle/report1";
import { RightOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, message, Modal, Row, Spin, Checkbox} from "antd";
import { Table } from "antd";
import { Switch } from "antd";
import "react-phone-number-input/style.css";
import StyledClientWrapper from "../style";
import styled from "styled-components";
import COLORS from "../../../Common/Colors";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setEditClientData, setGetAllClientData } from "../../../Redux/systemSlice";
import { useNavigate } from "react-router-dom";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid #00000024;
    border-radius: 10px;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    font-size: 0.9rem;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
  .ant-table-thead > tr > th::before {
    display: none !important;
  }
  .ant-table-cell-row-hover {
    background: #e3e8fc !important;
  }
`;

const DetailsData = ({ setViewClientData, selectedRow, setSelectedRow }) => {
  const getAllClientData = useSelector(
    (state) => state.systemTracking.getAllClientData
  );
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const elements=[];
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectDeleteData, setSelectDeleteData] = useState();
  // const dataSource = [
  //   {
  //     key: 1,
  //     clientName: "Dashboard (Mine, My Team, HR)",
  //     name: "dashboard",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: selectedRow?.dashboard,
  //   },
  //   {
  //     key: 2,
  //     clientName: "Check in and check out through Biometrics",
  //     name: "checkinout_biometric",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: selectedRow?.checkinout_biometric,
  //   },
  //   {
  //     key: 3,
  //     clientName: "Leave application through portal",
  //     name: "leaveapplication_portal",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: selectedRow?.leaveapplication_portal, // Toggle button is off
  //   },
  //   {
  //     key: 4,
  //     clientName: "Calendar view (Mine, Team, All)",
  //     name: "calender_view",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: selectedRow?.calender_view,
  //   },
  //   {
  //     key: 5,
  //     clientName: "Standard Reporting (Daily Attendance & Leave Report)",
  //     name: "standard_reporting",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: selectedRow?.calender_view,
  //   },
  //   {
  //     key: 6,
  //     clientName:
  //       "Exception Reports (Late check-in, Early Check out, Absent Report)",
  //     name: "exception_report",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: selectedRow?.exception_report,
  //   },
  //   {
  //     key: 7,
  //     clientName: "Support",
  //     name: "support",
  //     // pricing: "",
  //     availableIn: "Basic, Lite, Pro",
  //     access: selectedRow?.support,
  //   },
  //   {
  //     key: 8,
  //     clientName: "HR Resources (People, Policies)",
  //     name: "hr_resources",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: selectedRow?.hr_resources,
  //   },
  //   {
  //     key: 9,
  //     clientName: "Manage",
  //     name: "manage",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: selectedRow?.manage,
  //   },
  //   {
  //     key: 10,
  //     clientName: "Shift Management",
  //     name: "shift_management",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: selectedRow?.shift_management,
  //   },
  //   {
  //     key: 11,
  //     clientName: "Document Management",
  //     name: "document_management",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: selectedRow?.document_management,
  //   },
  //   {
  //     key: 12,
  //     clientName: "My Team",
  //     name: "my_teams",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: selectedRow?.my_teams,
  //   },
  //   {
  //     key: 13,
  //     clientName: "Monthly Attendance Reports",
  //     name: "monthly_attandance_report",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: selectedRow?.monthly_attandance_report,
  //   },
  //   {
  //     key: 14,
  //     clientName: "Asset Management",
  //     name: "asset_management",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: selectedRow?.asset_management,
  //   },
  //   {
  //     key: 15,
  //     clientName: "Mobile App",
  //     name: "mobile_app",
  //     // pricing: "",
  //     availableIn: "Lite, Pro",
  //     access: selectedRow?.mobile_app,
  //   },
  //   {
  //     key: 16,
  //     clientName: "Recruitment Management",
  //     name: "recruitment_manaement",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.recruitment_manaement,
  //   },
  //   {
  //     key: 17,
  //     clientName: "Automated Onboarding",
  //     name: "automated_onboarding",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.automated_onboarding,
  //   },
  //   {
  //     key: 18,
  //     clientName: "Appraisal Management",
  //     name: "apprisal_management",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.apprisal_management,
  //   },
  //   {
  //     key: 19,
  //     clientName: "Performance Management",
  //     name: "performance_management",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.performance_management,
  //   },
  //   {
  //     key: 20,
  //     clientName: "Travel/ Expense Approvals Management",
  //     name: "travel_expenses",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.travel_expenses,
  //   },
  //   {
  //     key: 21,
  //     clientName: "Expense Reimbursement",
  //     name: "expenses_reimbursment",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.expenses_reimbursment,
  //   },
  //   {
  //     key: 22,
  //     clientName: "Disciplinary Management",
  //     name: "disciplinary_management",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.disciplinary_management,
  //   },
  //   {
  //     key: 23,
  //     clientName: "Incident Reporting",
  //     name: "incident_reporting",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.incident_reporting,
  //   },
  //   {
  //     key: 24,
  //     clientName: "Simba AI",
  //     name: "simba_ai",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.simba_ai,
  //   },
  //   {
  //     key: 25,
  //     clientName: "Org Chart",
  //     name: "org_chart",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.org_chart,
  //   },
  //   {
  //     key: 26,
  //     clientName: "Activity Log",
  //     name: "activity_log",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.activity_log,
  //   },
  //   {
  //     key: 27,
  //     clientName: "Report Preferences",
  //     name: "report_preference",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.report_preference,
  //   },
  //   {
  //     key: 28,
  //     clientName: "Integration with existing Payroll system",
  //     name: "integrateex_payroll",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.integrateex_payroll,
  //   },
  //   {
  //     key: 29,
  //     clientName: "Payroll",
  //     name: "payroll",
  //     // pricing: "",
  //     availableIn: "Pro",
  //     access: selectedRow?.payroll,
  //   },
  // ];
  const dataSource=
  [
  
    {
          key: 1,
          clientName: "Dashboard - Mine",
          name: "dashboard",
          access: selectedRow?.dashboard,  
          planType: "Basic"   
    },
    {
          key: 2,
          clientName: "Check in and check out through Biometrics",
          name: "checkinout_biometric",
          access: selectedRow?.checkinout_biometric,
          planType: "Basic"  
        },
        {
          key: 3,
          clientName: "Leave application through portal",
          name: "leaveapplication_portal",
          access: selectedRow?.leaveapplication_portal,
          planType: "Basic"  
        },
        {
          key: 4,
          clientName: "Calendar view - Mine",
          name: "calender_view",
          access: selectedRow?.calender_view,
          planType: "Basic"  

        },
        {
          key: 5,
          clientName: "Calendar view - Team",
          name: "calender_view",
          access: selectedRow?.calender_view,
          planType: "Basic"  

        },
        {
          key: 6,
          clientName: "Calendar view - All",
          name: "calender_view",
          access: selectedRow?.calender_view,
          planType: "Basic"  

        },
        {
          key: 7,
          clientName: "Standard Reporting (Daily Attendance & Leave Report)",
          name: "standard_reporting",
          access: selectedRow?.calender_view,
          planType: "Basic"  

        },
        {
          key: 8,
          clientName:
            "Exception Reports - Late check-in ",
          name: "exception_report",
          access: selectedRow?.exception_report,
          planType: "Basic"  

        },
        {
          key: 9,
          clientName:
            "Exception Reports - Early Check out",
          name: "exception_report",
          access: selectedRow?.exception_report,
          planType: "Basic"  

        },
        {
          key: 10,
          clientName:
            "Exception Reports - Absent Report",
          name: "exception_report",
          access: selectedRow?.exception_report,
          planType: "Basic"  

        },
        {
          key: 11,
          clientName: "Support",
          name: "support",
          access: selectedRow?.support,
          planType: "Basic"  

        },
        {
          key: 12,
          clientName: "HR Resources - Policies",
          name: "hr_resources",
          access: selectedRow?.hr_resources,
          planType: "Lite"  

        },
        {
          key: 13,
          clientName: "HR Resources - People",
          name: "hr_resources",
          access: selectedRow?.hr_resources,
          planType: "Lite"  

        },
        {
          key: 14,
          clientName: "Manage",
          name: "manage",
          access: selectedRow?.manage,
          planType: "Lite"  

        },
        {
          key: 15,
          clientName: "Shift Management",
          name: "shift_management",
          access: selectedRow?.shift_management,
          planType: "Lite"  

        },
     
      {
            key: 16,
            clientName: "Document Management",
            name: "document_management",
           
            access: selectedRow?.document_management,
            planType: "Lite"
          },
          {
            key: 17,
            clientName: "Dashboard - My Team",
            name: "my_teams",
            
            access: selectedRow?.my_teams,
            planType: "Lite"
          },
          {
            key: 18,
            clientName: "Monthly Attendance Reports",
            name: "monthly_attandance_report",
            
            access: selectedRow?.monthly_attandance_report,
            planType: "Lite"
          },
          {
            key: 19,
            clientName: "Asset Management",
            name: "asset_management",
           
            access: selectedRow?.asset_management,
            planType: "Lite"
          },
          {
            key: 20,
            clientName: "Mobile App",
            name: "mobile_app",
           
            access: selectedRow?.mobile_app,
            planType: "Lite"
          },
          {
            key: 21,
            clientName: "Recruitment Management",
            name: "recruitment_manaement",
            
            access: selectedRow?.recruitment_manaement,
            planType: "Lite"
          },
          {
            key: 22,
            clientName: "Automated Onboarding",
            name: "automated_onboarding",
           
            access: selectedRow?.automated_onboarding,
            planType: "Lite"
          },
          {
            key: 23,
            clientName: "Appraisal Management",
            name: "apprisal_management",
           
            access: selectedRow?.apprisal_management,
            planType: "Lite"
          },
          {
            key: 24,
            clientName: "Dashboard - HR",
            name: "dashboard_hr",
            access: selectedRow?.dashboard_hr,  
            planType: "Pro"   
      },
          {
            key: 25,
            clientName: "Performance Management",
            name: "performance_management",
            
            access: selectedRow?.performance_management,
            planType: "Pro"
          },
          {
            key: 26,
            clientName: "Travel/ Expense Approvals Management",
            name: "travel_expenses",
            
            access: selectedRow?.travel_expenses,
            planType: "Pro"
          },
     
     
          {
            key: 27,
            clientName: "Expense Reimbursement",
            name: "expenses_reimbursment",
           
            access: selectedRow?.expenses_reimbursment,
            planType: "Pro"

          },
          {
            key: 28,
            clientName: "Disciplinary Management",
            name: "disciplinary_management",
            
            access: selectedRow?.disciplinary_management,
            planType: "Pro"

          },
          {
            key: 29,
            clientName: "Incident Reporting",
            name: "incident_reporting",
            access: selectedRow?.incident_reporting,
            planType: "Pro"

          },
          {
            key: 30,
            clientName: "Simba AI",
            name: "simba_ai",
            access: selectedRow?.simba_ai,
            planType: "Pro"

          },
          {
            key: 31,
            clientName: "Org Chart",
            name: "org_chart",
            access: selectedRow?.org_chart,
            planType: "Pro"

          },
          {
            key: 32,
            clientName: "Activity Log",
            name: "activity_log",
            access: selectedRow?.activity_log,
            planType: "Pro"

          },
          {
            key: 33,
            clientName: "Report Preferences",
            name: "report_preference",
            access: selectedRow?.report_preference,
            planType: "Pro"

          },
          {
            key: 34,
            clientName: "Integration with existing Payroll system",
            name: "integrateex_payroll",
            access: selectedRow?.integrateex_payroll,
            planType: "Pro"

          },
          {
            key: 35,
            clientName: "Payroll",
            name: "payroll",
            access: selectedRow?.payroll,
            planType: "Pro"
          },
     ]
 

  const handleClickEdit = (record) => {
    dispatch(setEditClientData(record));
    navigate(`editclient?clientId=${record.id}`, { replace: true });
  }

  const handleDelete = async (data, index) => {
    setSelectDeleteData(data);
    setDeleteModalOpen(true);
  };

  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    const url =
      "https://nhay2hvx12.execute-api.ap-northeast-1.amazonaws.com/Prod/deleteData";
    try {
      const response = await axios.delete(`${url}/${selectDeleteData.id}`);
      if (
        response.data.status === 200 ||
        response.data.status === 201 ||
        response.data.statusCode === 200 ||
        response.data.statusCode === 201
      ) {
        setLoading(false);
        const findIndex = getAllClientData.findIndex(
          (item) => item.id === selectDeleteData.id
        );
        if (findIndex !== -1) {
          const UpdatedClientData = [...getAllClientData];
          UpdatedClientData.splice(findIndex, 1);
          dispatch(setGetAllClientData(UpdatedClientData));
          setSelectedRow(null)
          setViewClientData(false)
        }
        setDeleteModalOpen(false);
        message.success("Deleted successfully!");
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
      setLoading(false);

      message.error("Failed to delete. Please try again.");
    }
  };

  const columns = [
    {
      title: "Feature Name",
      dataIndex: "clientName",
      key: "clientName",
    },
    // {
    //   title: "Pricing",
    //   dataIndex: "pricing",
    //   key: "pricing",
    // },
    // {
    //   title: "Available In",
    //   dataIndex: "availableIn",
    //   key: "availableIn",
    // },
    {
      title: "Access",
      dataIndex: "access",
      key: "access",
      render: (text, record) => (
        <Switch
          className="switch_button"
          defaultChecked
          name={record.clientName}
          size="small"
          checked={record.access}
        />
      ),
    },
  ];
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  const onChange1=(e) =>{
  console.log(`checked=${e.target.checked}`);
}

const groupedData = {
  Basic: dataSource.filter((item) => item.planType === "Basic"),
  Lite: dataSource.filter((item) => item.planType === "Lite"),
  Pro: dataSource.filter((item) => item.planType === "Pro"),
};
const renderPlanBox = (planType, data) => (
  <div
    key={planType}
    style={{
      border: "1px solid #ccc",
      borderRadius: "8px",
      padding: "20px",
      marginBottom: "20px",
      backgroundColor: "#f9f9f9",
      flex:"1",
    }}
  >
    <h3 style={{ textAlign: "center", color: "#333", marginBottom: "20px" }}>
      {planType} Plan
    </h3>
    {console.log('data-->',data)}
    {data.map((item) => (
      <div key={item.key} style={{ marginBottom: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span>{item.clientName}</span>
          <Checkbox style={{ width: "30px" }}
           checked={item.access} 
           onChange={onChange1} />
         
        </div>
      </div>
    ))}
  </div>
);

  return (
    <>
      <StyledClientWrapper>
        <div className="client_details_container">
          <div>
            <h5 style={report1.clientbtn} className="view_client_goback">
              <p onClick={() => setViewClientData(false)}>Client</p>{" "}
              <RightOutlined className="right_out_icon" />{" "}
              <storng>Details</storng>{" "}
            </h5>
          </div>
          <br></br>
          <Row gutter={12} className="client_details_wrapper">
            <Col xl={8}>
              <div className="client_details_data_wrap">
                <p>Client Name:</p>
                <p className="client_details_ans">{selectedRow?.client_name?.charAt(0).toUpperCase() + selectedRow?.client_name?.slice(1) || "-"}</p>
              </div>
              <div className="client_details_data_wrap">
                <p>Location: </p>
                <p className="client_details_ans">{selectedRow?.location?.charAt(0).toUpperCase() + selectedRow?.location?.slice(1) || "-"}</p>
              </div>
              <div className="client_details_data_wrap">
                <p>Contact Person:</p>
                <p className="client_details_ans">{selectedRow?.contact_person?.charAt(0).toUpperCase() + selectedRow?.contact_person?.slice(1) || "-"}</p>
              </div>
              <div className="client_details_data_wrap">
                <p>Email ID:</p>
                <p className="client_details_ans">{selectedRow?.email_id || "-"}</p>
              </div>
              <div className="client_details_data_wrap">
                <p>Company Name:</p>
                <p className="client_details_ans">
                  {selectedRow?.company_name?.charAt(0).toUpperCase() + selectedRow?.company_name?.slice(1) || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Subscriber Frequency:</p>
                <p className="client_details_ans">
                  {selectedRow?.supscription_frequency || "-"}
                </p>
              </div>
             
            </Col>

            <Col xl={8}>
              <div className="client_details_data_wrap">
                <p>Client Id:</p>
                <p className="client_details_ans">{`BMW${selectedRow?.id}` || "-"}</p>
              </div>
              <div className="client_details_data_wrap">
                <p>Employee Count:</p>
                <p className="client_details_ans">
                  {selectedRow?.employee_count || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Contact No:</p>
                <p className="client_details_ans">
                  {selectedRow?.contact_number || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Subscriber Count:</p>
                <p className="client_details_ans">
                  {selectedRow?.subscriber_count || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Discount:</p>
                <p className="client_details_ans">
                  {selectedRow?.discount || "-"}
                </p>
              </div>
              <div className="client_details_data_wrap">
                <p>Amount:</p>
                <p className="client_details_ans">
                  {selectedRow?.amount ? `${selectedRow.amount}$` : "-"}
                </p>
              </div>
              
              {/* <div className="client_details_data_wrap">
                <p>Password</p>
                <p className="client_details_ans">
                  {selectedRow?.employee_count}
                </p>
              </div> */}
            </Col>

            <Col xl={8}>
              <div className="client_detail_indigators">
              {selectedRow?.status && 
                <div className="client_status_type">
                  <p className={selectedRow?.status === "inactive" || "Inactive" ? "inactive_button" : "active_button"}>{selectedRow?.status?.charAt(0).toUpperCase() + selectedRow?.status?.slice(1)}</p>
                </div>
              }
                <div className="client_subscription_type">
                  &nbsp;&nbsp;
                  <div className="circle_icon"></div>{" "}
                  <span>{selectedRow?.subscription_plan?.charAt(0).toUpperCase() + selectedRow?.subscription_plan?.slice(1)}</span>
                </div>
                <EditOutlined onClick={() => handleClickEdit(selectedRow)}/>
                <DeleteOutlined onClick={() => handleDelete(selectedRow)} />
              </div>
            </Col>
          </Row>
          <br></br>

          {/* <div style={report1.scrollable}>
            <StyledTable
              dataSource={dataSource}
              columns={columns}
              rowClassName={() => "custom-row"}
              pagination={false}
            />
          </div> */}
          <div style={{ display: "flex", gap: "20px" }}>
    {Object.entries(groupedData).map(([planType, data]) => renderPlanBox(planType, data))}
  </div>
        </div>

        <Modal open={deleteModalOpen} footer={false} onCancel={handleCloseDelete}>
          <StyledClientWrapper>
            <p>Are you sure you want to delete this client?</p>
            <div className="delete_modal_footer">
              <Button
                className="cancel_button_style"
                onClick={handleCloseDelete}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmDelete}
                className="logout-button"
              >
                {loading ? <Spin /> : "Delete"}
              </Button>
            </div>
          </StyledClientWrapper>
        </Modal>
      </StyledClientWrapper>
    </>
  );
};

export default DetailsData;
