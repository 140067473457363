import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import BarChart from "../../reusableComponent/Charts/Bar";
import styled from "styled-components";
import { Spin } from "antd";
import axios from "axios";

const DashboardWrap = styled.div`
  .users-details-box {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    text-align: center;
    flex-direction: column;
    padding: 16px;
    gap: 14px;
    border-radius: 8px;
    border: 0.5px solid #e2e2e8;
    box-shadow: 0px 4px 27px 0px #00000017;
    background: #ffffff;
  }
  .user_details_box_container {
    justify-content: space-between;
  }
  .details_box_title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20.83px;
    text-align: left;
  }
  .user-type-details-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.83px;
    margin-bottom: 0px !important;
  }
  .users-details-box-col {
    width: 20%;
  }
  .clients-details-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .new-clients-data {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.23px;
    text-align: left;
    color: #1f243b;
  }
  .chart-container {
    width: 100%;
    border-radius: 8px;
    border: 0.5px solid #e2e2e8;
    box-shadow: 0px 4px 27px 0px #00000017;
    background: #ffffff;
  }
  .chart_title {
    padding: 10px 0px 0px 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.83px;
    text-align: left;
  }
  .location_details_titles {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 8px 16px;
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.23px;
      text-align: left;
    }
  }
  .location_details_wrapper {
    height: 254px;
    overflow: auto;
    padding: 0px 10px 8px 16px;
  }
  .location_details_list {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

function Dashboard() {
  const [locationData, setLocationData] = useState([]);
  const [clientData, setClientData] = useState({});
  const [monthlyDistribution, setMonthlyDistribution] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastWeekClientCount, setLastWeekClientCount] = useState();
  
  const getLastWeekClientCountForAllStatuses = (statusCreatedDates) => {
    // Get the current date
    const currentDate = new Date();
    
    // Calculate the date for 7 days ago
    const lastWeekDate = new Date();
    lastWeekDate.setDate(currentDate.getDate() - 7);
  
    // Object to store counts for each status
    const lastWeekClientCounts = {};
  
    // Iterate over each status (Inactive, Active, etc.)
    for (const status in statusCreatedDates) {
      const statusData = statusCreatedDates[status];
  
      // Filter the created_dates for this status and count the ones from the last week
      const count = statusData.created_dates.filter((date) => {
        // Convert each date to a Date object for comparison
        const createdDate = new Date(date);
        return createdDate >= lastWeekDate && createdDate <= currentDate;
      }).length;
  
      // Store the count for this status
      lastWeekClientCounts[status] = count;
    }
  
    return lastWeekClientCounts;
  };
  
  
  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      const url =
        "https://nhay2hvx12.execute-api.ap-northeast-1.amazonaws.com/Prod/dashboard";

      try {
        const response = await axios.get(url);
        if (
          response.data.status === 200 ||
          response.data.status === 201 ||
          response.data.statusCode === 200 ||
          response.data.statusCode === 201
        ) {
          console.log("response?.data?.response---->", response?.data?.response.clientList.statusCreatedDates)
          console.log("response---->", response?.data?.response)
          const lastWeekCount = getLastWeekClientCountForAllStatuses(response.data.response.clientList.statusCreatedDates);
          setLastWeekClientCount(lastWeekCount)
          setLocationData(response?.data?.response.location);
          setClientData(response?.data?.response.clientList);
          setMonthlyDistribution(response?.data?.response.monthlyDistribution);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);



  return (
    <>
      <DashboardWrap>
        
        <Row gutter={[16, 16]} className="">
          <Col sm={24} md={12} lg={8} xl={5} className="">
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">New </p>
                <p className="user-type-details-title">
                  {clientData?.newClients || 0}
                </p>
              </div>
              <span className="new-clients-data">({lastWeekClientCount?.new}) Recent Clients </span>
            </div>
          </Col>
          <Col sm={24} md={12} lg={8} xl={5}  className="">
           
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">Lead </p>
                <p className="user-type-details-title">
                  {clientData?.leadClients || 0}
                </p>
              </div>
              <span className="new-clients-data">({lastWeekClientCount?.lead}) Recent Clients </span>
            </div>
          </Col>
          <Col sm={24} md={12} lg={8} xl={5}  className="">
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">Follow-up </p>
                <p className="user-type-details-title">
                  {clientData?.followupClients || 0}
                </p>
              </div>
              <span className="new-clients-data">({lastWeekClientCount?.followup}) Recent Clients </span>
            </div>
          </Col>
          <Col sm={24} md={12} lg={8} xl={5}  className="">
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">Active </p>
                <p className="user-type-details-title">
                  {clientData?.activeClients || 0}
                </p>
              </div>
              <span className="new-clients-data">({lastWeekClientCount?.active}) Recent Clients </span>
            </div>
          </Col>
          <Col sm={24} md={12} lg={8} xl={4}  className="">
            <div className="users-details-box">
              <div className="clients-details-wrapper">
                <p className="user-type-details-title">Inactive </p>
                <p className="user-type-details-title">
                  {clientData?.inactiveClients || 0}
                </p>
              </div>
              <span className="new-clients-data">({lastWeekClientCount?.Inactive}) Recent Clients </span>
            </div>
          </Col>
        </Row>
        <br></br>
        
        <Row gutter={6}>
          {loading ? (
            <div style={{marginLeft: '500px'}}>
              <Spin />
            </div>
            
          ) : (
            <>
              <Col sm={24} md={24} lg={12} xl={12}>
                <div className="chart-container">
                  <p className="chart_title">Client Onboarding Count</p>
                  <BarChart data={monthlyDistribution} />
                </div>
              </Col>
              <Col sm={24} md={24} lg={12} xl={12}>
                <div className="chart-container">
                  <p className="chart_title">Client Locations</p>
                  <div className="location_details_titles">
                    <p>Country</p>
                    <p>Client Count</p>
                  </div>
                  <div className="location_details_wrapper">
                    {Object.entries(locationData).map(([country, count], index) => (
                      <div key={index} className="location_details_list">
                        <p>{country.charAt(0).toUpperCase() + country.slice(1)}</p>
                        <p>{count}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>




      </DashboardWrap>
    </>
  );
}

export default Dashboard;
