import React, { useState } from 'react'
import styled from 'styled-components';
import { Modal, Button, Spin } from 'antd';
import { LogoutOutlined } from '@mui/icons-material';
import LogoIcon from "../../assets/logo.svg";
import {ReactComponent as ProfileImg} from "../../assets/Icons/profile-icon.svg";

const HeaderMenuWrap = styled.div`
    .header-container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 8px 14px 10px;
        background: rgb(255, 255, 255);
        border-bottom: 1px solid rgb(240, 240, 240);
        img{
            width: 180px;
            cursor: pointer
        }
    }
    .profile-icon{
        cursor: pointer
    }
    .logout-icon {
        color: rgb(45, 83, 218);
        font-size: 24px; /* Adjust size */
        cursor: pointer;
        margin-right: 30px;
    }
`;

const HeaderBox = () => {
    const [logoutModalOpen, setLogoutModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);


    // Open the modal for logout

    const handleLogoutClick = () => {
        setLogoutModalOpen(true);
    }

    // Close the modal
    const handleCloseLogout = () => {
        setLogoutModalOpen(false);
    }

    // Handle the confirm logout action
    const handleConfirmLogout = () => {
        setLoading(true);
        setTimeout(() => {
        console.log("User logged out!");
        setLogoutModalOpen(false);
        window.location.href = 'https://dev.website.tymeplushr.com/login';
        setLoading(false);
        // Add your logout logic here (e.g., clear session, redirect to login)
        }, 1000);
    };


    return(
        <>
            <HeaderMenuWrap>
                <div className='header-container'>
                    <img src={LogoIcon} alt="logo-image" className="header_logo_title" />
                    {/* <div>
                        <ProfileImg className='profile-icon'/>
                    </div> */}
                        <LogoutOutlined className="logout-icon" onClick={handleLogoutClick}/> 
                </div>
            




            {/* Modal for Logout Confirmation */}
            <Modal
                open={logoutModalOpen}
                footer={false}
                onCancel={handleCloseLogout}
                
            >
                <div style={{ textAlign: 'left' }}>
                    <p>Are you sure you want to logout?</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button 
                            onClick={handleCloseLogout} 
                            className="cancel-button"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmLogout}
                            className='logout-button'
                        >
                            {loading ? <Spin /> : 'Logout'}
                        </Button>
                    </div>
                </div>
            </Modal>

            </HeaderMenuWrap>

        </>
    )
}

export default HeaderBox;